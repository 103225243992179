import classNames from 'classnames'
import styles from './Hero.module.scss'

const Hero = ({ children, className, isHeroImageTest }) => {
    const classes = classNames(
        styles.heroImage,
        { [styles.heroImageV1]: isHeroImageTest },
        className
    )

    return (
        <div className={classes}>
            { children }
        </div>
    )
}

export default Hero
