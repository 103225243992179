import { useEffect } from 'react'

import { init as ChatInit } from '@saatva-bits/pattern-library.utils.chat'

// DEF-27719: the timeout was set to 0 as a temporal solution to avoid chats overlapping issues
const timeoutDuration = 0

function useDeferScripts () {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const timer = setTimeout(() => {
                ChatInit()
            }, timeoutDuration)

            return () => {
                clearTimeout(timer)
            }
        }
    }, [])
}

export default useDeferScripts